@use "../../abstracts/" as abs;

.subCanvas.custom-offcanvas {

    .subCanvas_header {
        .subCanvas_header_title {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 15px 0;

            h2 {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
            }

            span {
                color: #B7BFCC;
                font-size: 22px;
                cursor: pointer;
            }

        }

        .subCanvas_header_img {
            width: 100%;
            position: relative;

            img {
                width: 100%;
            }

            .canvas_text {
                position: absolute;
                top: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding: 25px;
                gap: 10px;

                h1 {
                    font-size: 26px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #fff;
                }

                button {
                    padding: 15px 26px;
                    border: none;
                }
            }
        }
    }

    .offcanvas-body {
        max-height: 100%;
        overflow: hidden;
        max-width: 330px;
    }

    .subCanvas__body {
        .bodyTitle {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: abs.$text-blue;
            font-weight: 600;
            margin: 15px 0;
            display: block;
        }

        .subCanvas_body_list {

            .subMega {
                max-height: 300px;
                /* Set your desired max height */
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 20px;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #d6dee1;
                    border-radius: 20px;
                    border: 6px solid transparent;
                    background-clip: content-box;
                    width: 6px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: #a8bbbf;
                }

                .mega-item {
                    text-decoration: none !important;
                    margin-bottom: 20px;
                    display: flex;

                    .blob {
                        margin-right: 10px;

                        &::after {
                            content: "";
                            display: block;
                            width: 24px;
                            border-radius: 8px;
                            background: var(--Background, #f4f8ff);
                            height: 24px;
                        }
                    }

                    .txtbox {
                        width: calc(100% - 35px);
                    }

                    .name {
                        color: abs.$text-dark;
                        font-family: abs.$font-family;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%;

                        &.active {
                            color: abs.$primary-color;
                            text-decoration-line: none;
                        }
                    }

                    .desc {
                        color: abs.$text-blue;
                        font-family: abs.$font-family;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }

                    .blob {
                        // display: none;
                        width: 24px;

                        svg {
                            display: none;

                            path {
                                fill: abs.$primary-color;
                            }
                        }

                        &::after {
                            content: none;
                        }
                    }

                    &:hover {
                        .name {
                            color: abs.$primary-color;
                            text-decoration-line: underline;
                        }

                        .blob {

                            // display: flex;
                            svg {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}