@use "../../abstracts" as abs;

.treatment-home {
  background-color: #fff;
  border-radius: 36px;
  padding: 53px 0px;

  @include abs.respond-to(mobile) {
    padding: 22px 0px;
    background-color: #fff0;
    border-radius: 0px;
  }

  &>.container>.row {
    margin: 0px;

    background-color: #fff;
    padding: 17px;
    border-radius: 12px;

    &>.col-12 {
      padding: 0px;
    }
  }

  .treatment-track {
    justify-content: center;
    // height: 602px;
    // overflow: hidden;

    @include abs.respond-to(mobile) {
      // height: fit-content;
      // height: auto;
    }

    &.seemore {
      height: fit-content;
      transition: all 0.5s ease;

      @include abs.respond-to(mobile) {
        .dissapear-onmobile {
          display: block !important;
        }
      }
    }

    .treatmentItem {
      

      &.dissapear-onmobile {
        @include abs.respond-to(mobile) {
          display: none;
        }
      }
      
    }

    .see_more {
      span {
        cursor: pointer;
      }
    }
  }
}