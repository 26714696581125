@use "../../abstracts" as abs;

.addDependant {
  .MuiPaper-root.MuiDrawer-paper {
    overflow-y: auto;
    padding: 50px 50px;
    width: 460px;
    height: 100vh;
    border-radius: 12px;
    max-height: 100vh;

    @include abs.respond-to(mobile) {
      width: 100vw;
      padding: 10px;
    }

    .countryCOdeparent {
      .MuiAutocomplete-root {
        .MuiFormControl-root {
          .MuiInputBase-root {
            .MuiAutocomplete-endAdornment {
              top: 50%;
            }
          }
        }
      }
    }

    .head {
      color: abs.$text-primary;
      font-size: 18px;
      font-family: abs.$font-family;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      /* Light grey background */
    }

    &::-webkit-scrollbar-thumb {
      background-color: abs.$back2-color;
      /* Grey thumb */
      border-radius: 6px;
      /* Rounded thumb corners */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      /* Darker grey on hover */
    }
  }
}