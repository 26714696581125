@use "../../abstracts" as abs;

.appt-slots {
  padding: 23px 19px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  .day {
    color: #263077;
    font-size: 16px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 6px;
  }

  .date {
    color: abs.$secondary-color;
    font-size: 24px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
  }

  .timeslotslistparent {
    .h1 {
      color: abs.$text-dark;
      font-size: 14px;
      font-family: abs.$font-family;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 8px;
      display: block;
    }

    .list {
      max-height: 226px;
      overflow-y: scroll;
      overflow-x: hidden;

      .slot {
        padding: 14px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        color: abs.$text-blue;
        background: rgba(38, 48, 119, 0.05);
        cursor: pointer;
        border-radius: 25px;
        margin: 5px 0px;
        transition: all 0.2s;

        &:hover {
          background-color: abs.$secondary-color;
          color: #fff;
        }

        &.checked {
          background-color: abs.$secondary-color;
          color: #fff;
        }
      }

      .noSlot {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 150px;
        }

        span {
          color: #263077;
          font-weight: 700;
          margin-top: 15px;
        }
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 6px;
        border: 1px solid transparent;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
      }
    }
  }
}
