@use "../../abstracts" as abs;

.share_feadback {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    position: relative;
    background-color: abs.$primary-color;

    .header{
        font-size: 22px;
        font-weight: 600;
        color: #fff;
    }

    .img{
        position: absolute;
        right: 0;
        bottom: 0;
    }
}