@use "../../abstracts" as abs;

.hpmodal {
  .header {
    color: abs.$text-dark;
    font-family: abs.$font-family;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    span {
      display: block;
      color: abs.$text-gray;
      font-family: abs.$font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 5px;
    }
  }

  .body {
    padding: 27px 97px;
    overflow: hidden;
    height: 313px !important;

    @include abs.respond-to(mobile) {
      height: 503px !important;
      padding: 0px;

      & > div.row {
        display: flex;
        flex-direction: column-reverse;

        .col-sm-4 {
          text-align: center;
          margin: 20px 0px 35px 0px;
        }
      }
    }

    .bpox-p {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .bpox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        @include abs.respond-to(mobile) {
          align-items: center;
        }

        .hd {
          color: abs.$primary-color;
          font-family: abs.$font-family;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .input {
          width: 75%;
          border-radius: 50px;
          border: 1px solid abs.$primary-color;
          padding: 10px 13px;
          display: flex;
          margin-top: 15px;

          @include abs.respond-to(mobile) {
            width: 82%;
          }

          input {
            border: none;
            flex-grow: 1;
            max-width: 100%;
            width: 100%;

            &:focus-visible {
              outline: none !important;
            }
          }

          .dropper {
            .MuiSelect-select.MuiSelect-outlined {
              padding: 2px 32px 2px 9px !important;
              border-radius: 50px;
              background: abs.$text-dark;
              color: #fff;
            }

            .MuiOutlinedInput-notchedOutline {
              border: none !important;

              span {
                display: none !important;
              }
            }

            svg {
              path {
                fill: #fff !important;
              }
            }
          }

          .staticspan {
            span {
              display: block;
              padding: 2px 9px 2px 9px !important;
              border-radius: 50px;
              background: abs.$text-dark;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .MuiStepper-root {
    .MuiStepLabel-iconContainer {
      & > div {
        color: abs.$primary-color;
        display: flex;
        height: 22px;
        align-items: center;

        .QontoStepIcon-completedIcon {
          color: abs.$primary-color;
          z-index: 1;
          font-size: 18px;
          user-select: none;
          width: 1em;
          height: 1em;
          display: inline-block;
          fill: currentColor;
          flex-shrink: 0;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-size: 1.5rem;
        }

        .QontoStepIcon-circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #eaeaf0;
        }
      }

      &.Mui-active {
        .QontoStepIcon-circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: abs.$primary-color;
        }
      }
    }

    .MuiStepConnector-root {
      top: 10px;
      left: calc(-50% + 16px);
      right: calc(50% + 16px);
      span {
        display: block;
        border-top-style: solid;
        border-color: #eaeaf0;
        border-top-width: 3px;
        border-radius: 1px;
      }

      &.Mui-active,
      &.Mui-completed {
        span {
          border-color: abs.$primary-color;
        }
      }
    }
  }
}

.aup {
  position: absolute;
  right: 10px;
  top: 54px;
}
