@use "../../abstracts" as abs;

.imageGallary {

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-content: space-between;

  .gal-card {
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 16 / 11;
    max-width: 100%;
    width: 100%;

    .img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
    }

    @include abs.respond-to(mobile) {
      width: 246px;
    }
  }

  .swiper-pagination {
    display: none;
  }
}

.mySwiper {
  .swiper-wrapper {
    .swiper-slide {

      &.gal-card {
        border-radius: 8px;
        overflow: hidden;
        aspect-ratio: 16 / 11;
        max-width: 100%;
        width: 100%;
        margin-right: 15px;

        .img {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 8px;
        }

        @include abs.respond-to(mobile) {
          width: 246px;
          aspect-ratio: 16 / 11;
        }
      }

    }
  }
}


.img-gal {
  height: 0px !important;
  width: 0px !important;
  visibility: hidden !important;
  overflow: hidden !important;


}

.image-gallery.fullscreen-modal {
  background: #00000000 !important;

  .image-gallery-content.fullscreen {
    background: #000000a3 !important;

    @include abs.respond-to(mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100% !important;
    }

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      top: 0 !important;
      bottom: unset !important;
    }

    .image-gallery-play-button {
      left: unset !important;
      right: 68px !important;
    }
  }
}