@use "../abstracts" as abs;

.main-body.contect-page {
    .container {
        font-family: abs.$font-family;

        .back_from {
            display: none;
            background-color: #fff;
            padding: 20px 0;
            border-radius: 12px;
            align-items: center;
            gap: 10px;
            text-align: center;
            justify-content: center;
            position: relative;
            font-size: 21px;
            margin-top: 20px;

            @include abs.respond-to(mobile) {
                display: flex;
            }

            span {
                font-size: 40px !important;
                cursor: pointer;
                position: absolute;
                left: 15px;
            }
        }

        .contect_us {
            margin: 30px 0;
            background-color: #fff;
            border-radius: 12px;
            padding: 20px;
        }

        .contect_title {
            text-align: center;
            margin: 30px;

            h2 {
                margin-bottom: 20px;
            }
        }

        .main_contect {
            display: grid;
            grid-template-columns: 2fr 3fr;
            min-height: 650px;

            @include abs.respond-to(mobile) {
                display: flex;
                flex-direction: column-reverse;
            }

            .contect_info {
                background-color: abs.$primary-color;
                color: #fff;
                border-radius: 12px;
                padding: 60px 40px;
                position: relative;

                .con_item {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    margin: 25px 0;

                    @include abs.respond-to(mobile) {
                        font-size: 12px;
                    }
                }

                .b_mark {
                    max-width: 200px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .contect {
            padding: 30px;

            @include abs.respond-to(mobile) {
                padding: 30px 0;
            }

            .input_div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
                margin-bottom: 20px;

                @include abs.respond-to(mobile) {
                    grid-template-columns: 1fr;
                    gap: 10px;
                    margin-bottom: 10px;
                }

                .input {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0;

                    // label {
                    //     margin-bottom: 10px;
                    // }

                    input {
                        padding: 10px 0;
                        border: none;
                        outline: none;
                        border-bottom: 2px solid #B7BFCC;
                    }
                }

                &.phone_div {
                    display: flex;
                    flex-direction: column;
                    margin: 5px 0;
                    gap: 0px;
                    overflow: hidden;

                    .phone-no-grp {
                        padding: 10px 0;
                        border-bottom: 2px solid #B7BFCC;
                        align-items: center;

                        .inputmix {
                            input {
                                border: none;
                                width: 100%;
                                @include abs.respond-to(mobile) {
                                    margin-left: 5px;
                                }
                            }
                        }

                        .countryCOdeparent {
                            .MuiAutocomplete-root {
                                .MuiFormControl-root {
                                    .MuiInputBase-root {
                                        .MuiAutocomplete-endAdornment {
                                            top: 50%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .input_b {
                display: flex;
                flex-direction: column;
                margin: 10px 0;
                margin-bottom: 40px;

                @include abs.respond-to(mobile) {
                    margin-bottom: 20px;
                }

                label {
                    margin-bottom: 10px;
                }

                input {
                    padding: 10px 0;
                    border: none;
                    outline: none;
                    border-bottom: 2px solid #B7BFCC;
                }
                textarea {
                    padding: 10px 0;
                    border: none;
                    outline: none;
                    border-bottom: 2px solid #B7BFCC;
                    min-height: 100px;
                }
            }

            .input_div,
            .input_b {
                .error{
                    color: red;
                    font-size: 12px;
                    margin: 0px;
                }
            }

            .submit {
                display: flex;
                width: 100%;
                justify-content: end;

                .disabled{
                    background-color: abs.$text-gray;
                    border: abs.$text-gray;
                    cursor: not-allowed !important;
                    &:hover{
                        &::before{
                            display: none;
                        }
                    }
                }

                @include abs.respond-to(mobile) {
                    justify-content: center;
                }
            }

        }

        .download-contect {
            .base-cont {
                padding: 15px;
                background: #fff;
                border-radius: 12px;
                margin-top: 20px;

                @include abs.respond-to(mobile) {
                    border-radius: 12px;
                }

                .left-side {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    z-index: 2;
                    margin: 8px 0px 20px 73px;

                    @include abs.respond-to(mobile) {
                        margin: 0px auto;
                        display: flex;
                        flex-direction: column;
                        // align-items: flex-start;
                        align-items: center;
                    }

                    .uorApp_pill {
                        display: flex;
                        width: 100%;
                        align-items: flex-start;
                    }

                    .para {
                        display: block;
                        font-family: abs.$font-family;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 23px;

                        /* Text Color */

                        // color: abs.$text-blue;
                        color: #536288;

                        margin: 0px 0px 20px 0px;

                        @include abs.respond-to(mobile) {
                            font-size: 12px;
                            line-height: 180%;
                            margin: 10px 0px;
                        }
                    }

                    button {
                        margin-bottom: 27px;
                    }
                }

                .right-side {
                    position: relative;
                    height: 516px;

                    img {
                        // width: 100%;
                        height: 100% !important;

                        @include abs.respond-to(mobile) {
                            height: auto;
                        }
                    }

                    @include abs.respond-to(mobile) {
                        display: flex;
                        justify-content: center;
                        height: 516px;
                        margin-top: 20px;
                    }

                    img.app1 {
                        position: absolute;
                        right: -41px;
                        top: 50%;
                        transform: translateY(-50%);

                        @include abs.respond-to(mobile) {
                            left: 50%;
                            top: 50%;
                            // width: 90%;
                            transform: translate(-45%, -50%);
                        }
                    }

                    img.app2 {
                        position: absolute;
                        right: 191px;
                        top: 66%;
                        transform: translateY(-59%);

                        @include abs.respond-to(mobile) {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}