@use "../../abstracts" as abs;

.treatment-track {
    .treatmentItem {
        display: flex;
        justify-content: center;
        align-items: center;

        .item {
            background: #ffffff;
            text-decoration: none !important;
            box-shadow: abs.$box-shadow-top;
            border-radius: 18px;
            width: 267px;
            height: 281px;
            padding: 12px;
            margin: 10px 0px;
            transition: all 0.3s ease;
            overflow: hidden;

            @include abs.respond-to(mobile) {
                width: 155px;
                height: 185px;
                padding: 5px;
                border-radius: 12px;
            }

            .title {
                font-family: abs.$font-family;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 120%;
                color: #242424;
                margin-top: 9px;
                margin-bottom: 5px;
                height: 38px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                @include abs.respond-to(mobile) {
                    height: 34px;
                    font-size: 14px;
                    line-height: 120%;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            .image {
                position: relative;
                text-align: center;

                img {
                    max-height: 168px;
                    width: 100%;
                    border-radius: 12px;
                }

                span {
                    position: absolute;
                    right: 0px;
                    bottom: -7px;
                    color: #fff;
                    width: -moz-fit-content;
                    width: fit-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 6px 10px;
                    font-family: abs.$font-family;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 120%;
                    background-color: abs.$primary-color;
                    border: 2px solid #fff;
                    border-radius: 20px;

                    @include abs.respond-to(mobile) {
                        font-size: 9px;
                        padding: 3px 8px;
                    }
                }
            }

            .float_area {
                position: relative;

                .text {
                    position: absolute;
                    transform: translateX(0);
                    transition: transform 0.3s ease;
                    width: 100%;
                    font-family: abs.$font-family;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    color: abs.$text-blue;

                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    @include abs.respond-to(mobile) {
                        font-size: 10px;
                        line-height: 14px;
                        -webkit-line-clamp: 2;
                    }

                }

                .view_button {
                    overflow: hidden;
                    position: absolute;
                    width: 100%;
                    display: flex;

                    a {
                        padding: 6px 10px;
                        transform: translateX(-200px);
                        transition: transform 0.3s ease;

                        @include abs.respond-to(mobile) {
                            font-size: 10px !important;
                        }
                    }
                }
            }

            &:hover {
                border: 1px solid abs.$primary-color;

                .float_area {

                    .text {
                        transform: translateX(300px);
                    }

                    .view_button {
                        display: flex;
                        align-items: center;

                        a {
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }
    }
}