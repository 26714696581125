@use "../../abstracts" as abs;

.patinetDocs {
  border-radius: 12px;

  .count {
    display: grid;
    grid-template-columns: 190px 1fr;
    grid-template-areas: "left-side rov-parent" ;
    gap: 20px;
    margin: 0;
    // margin-top: 15px;
    padding: 15px 14px;

    @include abs.respond-to(mobile) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "rov-parent"
        "left-side";
    }

    .left_side {
      grid-area: left-side;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background: #2cb8df17;
      border-radius: 12px;
      padding: 14px;
      height: 100%;
      gap: 10px;

      @include abs.respond-to(mobile) {
        gap: 15px;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .rov-parent {
      grid-area: rov-parent;
      border-radius: 8px;
      border: 1px dashed var(--dfdf, #2cb7df);
      background: #2cb8df17;
      padding: 16px 14px 14px 14px;

      .rov {
        font-family: abs.$font-family;
        color: abs.$primary-color;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
      }

      .para {
        font-family: abs.$font-family;
        color: abs.$text-dark;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 19.5px */
      }
    }

    .file-status {
      display: flex;
      align-items: center;

      .file_text {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          padding-right: 5px;
          width: 26px;
        }

        span.counter {
          padding-right: 2.5px;
          color: abs.$secondary-color;
          font-family: abs.$font-family;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          /* 24px */
        }

        span.sub {
          color: abs.$text-blue;
          font-family: abs.$font-family;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }


    }
  }



  .title {
    font-family: abs.$font-family;
    color: abs.$primary-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 2px solid #e5e5e5;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include abs.respond-to(mobile) {
      font-size: 16px;
    }

    button {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  // .rov-parent {
  //   grid-area: rov-parent;
  //   border-radius: 8px;
  //   border: 1px dashed var(--dfdf, #2cb7df);
  //   background: #2cb8df17;;
  //   padding: 16px 14px 14px 14px;

  //   .rov {
  //     font-family: abs.$font-family;
  //     color: abs.$primary-color;
  //     font-size: 15px;
  //     font-style: normal;
  //     font-weight: 600;
  //     line-height: normal;
  //     margin-bottom: 10px;
  //   }

  //   .para {
  //     font-family: abs.$font-family;
  //     color: abs.$text-dark;
  //     font-size: 13px;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: 150%;
  //     /* 19.5px */
  //   }
  // }
}