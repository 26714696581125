@use "../../abstracts" as abs;

.userdrop-parent {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-areas: "usrimg usrname usrbtn";
  width: 187px;
  height: 48px;
  align-items: center;
  position: relative;
  cursor: pointer;

  @include abs.respond-to(mobile) {
    width: 48px;
    grid-template-columns: 100%;
    grid-template-areas: "usrimg";
  }

  .userdrop-img {
    grid-area: usrimg;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .userdrop-name {
    grid-area: usrname;
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* or 143% */

    /* leading-trim and text-edge are draft CSS properties.

        Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
        */
    leading-trim: both;
    text-edge: cap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #263077;

    @include abs.respond-to(mobile) {
      display: none;
    }
  }
  .userdrop-btn {
    grid-area: usrbtn;
    display: flex;
    justify-content: center;
    align-items: center;
    @include abs.respond-to(mobile) {
      display: none;
    }
  }

  .userdrop-menu {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(38, 48, 119, 0.08);
    padding: 5px 14px;
    border-radius: 12px;
    top: 100%;
    width: 100%;
    margin-top: 18px;
    z-index: 3;
    min-width: 170px;
    @include abs.respond-to(mobile) {
      // display: none;
      min-width: 170px;
      right: 0;
    }

    .menu-list {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      cursor: pointer;
      text-decoration: none;

      .menu-list-icon {
        display: flex;
        justify-content: center;
        font-size: 25px;

        span {
          color: abs.$text-gray;
        }
      }

      .menu-list-name {
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */

        display: flex;
        align-items: center;
        padding-left: 0px;
        /* Text Color */

        color: abs.$text-dark;
      }

      .menu-list-logout {
        font-family: abs.$font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 114% */

        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding-left: 0px;
        /* Text Color */

        color: abs.$warning-color;

        span {
          padding-right: 9px;
        }
      }
    }
  }
}
